import { useState } from "react"
import { ProfileSummaryType, ThatchProStatus } from "~/endpoints/model"
import { useScreenSize } from "~/hooks"
import { ProfileImageWithVideo } from "./ProfileImageWithVideo"
import { Typography } from "~/components/shared/text/Typography"
import { ReviewCardSummary } from "~/components/reviews/ReviewSummary"
import { ThatchLink } from "~/components/shared/ThatchLink"
import { ThatchButton } from "~/components/shared/ThatchButton"
import { HireMeButton } from "../HireMeButton"

type ProfileCardProps = {
    data: ProfileSummaryType
}

export default function ProfileCard({ data }: ProfileCardProps) {
    const { isMobileScreen } = useScreenSize()
    const [playVideo, setPlayVideo] = useState(false)

    const PROFILE_PICTURE_WIDTH = isMobileScreen ? 150 : 168
    const PROFILE_PICTURE_HEIGHT = isMobileScreen ? 200 : 224

    return (<>
        <div onMouseEnter={() => setPlayVideo(true)} onMouseLeave={() => setPlayVideo(false)} className="py-8">
            <div className="flex items-center md:items-stretch gap-4 group">
                <div style={{ flexShrink: 0, borderRadius: 8, overflow: 'clip', width: PROFILE_PICTURE_WIDTH, height: PROFILE_PICTURE_HEIGHT }}>
                    <ProfileImageWithVideo profile={data} w={PROFILE_PICTURE_WIDTH} h={PROFILE_PICTURE_HEIGHT} play={playVideo || isMobileScreen} />
                </div>
                <div className="flex flex-col gap-2 grow min-h-0">
                    <div className="flex items-center gap-2">
                        <Typography variant="title" lineClamp={1}>{data.name}</Typography>
                        <div className="hidden md:block">
                            <ProTag proStatus={data.proStatus} />
                        </div>
                    </div>
                    {data.home && <Typography variant={isMobileScreen ? 'body1' : 'body3'} className="text-black">{data.home.name}</Typography>}
                    <div className="hidden md:block">
                        <Typography className="max-w-full overflow-x-hidden my-2" variant="body2" lineClamp={2} style={{ textOverflow: 'ellipsis', display: '-webkit-box' }}>{data.bio?.substring(0, 1000)}</Typography> {/* Substring - because a few sellers have long lines at the bottom of their text which screws up the width here */}
                    </div>
                    <div className="flex items-center gap-4">
                        <ReviewCardSummary rating={data.rating} reviews={data.reviews} />
                        <div className="block md:hidden">
                            <ProTag proStatus={data.proStatus} />
                        </div>
                    </div>
                    <div className="hidden md:block grow" />
                    <div className="hidden md:flex gap-1.5 justify-end">
                        <ThatchLink to={`/@${data.username}`} isOpenInNewWindow>
                            <ThatchButton variant="outlined" className="border border-solid border-black/25 w-44 h-10">
                                <Typography variant="body3">View Profile</Typography>
                            </ThatchButton>
                        </ThatchLink>
                        {data.hireable && <HireMeButton profileDetails={data} buttonClass="h-10" />}
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:hidden gap-4 mt-4">
                <Typography className="max-w-full overflow-x-hidden" variant="body1" lineClamp={2} style={{ textOverflow: 'ellipsis', display: '-webkit-box' }}>{data.bio?.substring(0, 1000)}</Typography> {/* Substring - because a few sellers have long lines at the bottom of their text which screws up the width here */}
                <div className="grid grid-cols-2 gap-1.5">
                        <ThatchLink to={`/@${data.username}`} className="w-full">
                            <ThatchButton variant="outlined" className="border border-solid border-black/25 w-full h-10">
                                <Typography variant="body3">View Profile</Typography>
                            </ThatchButton>
                        </ThatchLink>
                        {data.hireable && <HireMeButton profileDetails={data} buttonClass="h-10" />}
                    </div>
            </div>
        </div>
    </>)
}

function ProTag({ proStatus }: { proStatus: ThatchProStatus }) {
    if (proStatus == ThatchProStatus.none) return null
    if (proStatus == ThatchProStatus.newTalent) return null


    const label = {
        [ThatchProStatus.newPro]: 'New Pro',
        [ThatchProStatus.risingPro]: 'Rising Pro',
        [ThatchProStatus.topPro]: 'Top Pro',
    }[proStatus]

    const backgroundColour = {
        [ThatchProStatus.newPro]: '#FFF2FC',
        [ThatchProStatus.risingPro]: '#EBFFED',
        [ThatchProStatus.topPro]: '#DBEDFF',
    }[proStatus]

    const textColour = {
        [ThatchProStatus.newPro]: '#D05BB9',
        [ThatchProStatus.risingPro]: '#00A610',
        [ThatchProStatus.topPro]: '#426BFF',
    }[proStatus]

    return <div className="py-0.5 px-1 rounded-md h-[22px]" style={{ background: backgroundColour, color: textColour }}>
        <Typography variant="caption" style={{ color: textColour }}>
            {label}
        </Typography>
    </div>
}